import React, { Component } from 'react'
import './Sidebar.css'
import { Button } from 'react-bootstrap'
import logouticon from '../assets/images/log-out.svg'
import qricon from '../assets/images/qrcode-scan.svg'
import settingsicon from '../assets/images/settings.svg'
import recallicon from '../assets/images/recall.svg'
import bellicon from '../assets/images/bell.svg'
import { Route, NavLink } from 'react-router-dom'
import logo from '../assets/images/logo.svg'

export class Sidebar extends Component {

  handleLogout = () => {
    this.props.logout()
  }

  handleQrcode = () => {
    console.log('click')
  }

  render() {
    return (
      <>
        <div className='sidebar'>
          <nav>
            <div className="d-flex justify-content-center align-items-center menu-box">
                <img src={logo} alt="" className='logo'/>
            </div>

            <div className="d-flex justify-content-center mt-4 pt-4">
                
            </div>

            <div className="d-flex justify-content-center mt-4 menu-box">
              <NavLink to='/' exact="true" className='w-100 menu-bars'>
                <img src={qricon} alt="" className='btn-qricon'/> QR Code Generator
              </NavLink>
            </div>

            <div className="d-flex justify-content-center menu-box">
              <NavLink to='/chequenotification' exact="true" className='w-100 menu-bars'>
                <img src={bellicon} alt="" className='btn-qricon'/> Cheque Notification
              </NavLink>
            </div>

            <div className="d-flex justify-content-center menu-box">
              <NavLink to='/claims' className='w-100 menu-bars'>
                <img src={recallicon} alt="" className='btn-qricon'/> Claims
              </NavLink>
            </div>

            <div className="d-flex justify-content-center menu-box">
              <NavLink to='/settings' className='w-100 menu-bars'>
                <img src={settingsicon} alt="" className='btn-qricon'/> Settings
              </NavLink>
            </div>
          </nav>

        </div>

        <div className='btn-logout-div'>
          <Button size='lg' className='btn-logout' onClick={this.handleLogout}>
            <div className='btn-logout-text'>
              <img src={logouticon} alt="" className='btn-icon'/> Logout
            </div>
          </Button>
        </div>
      </>
    )
  }
}

export default Sidebar