import { functions } from "../firebase"

var XLSX = require('xlsx')


let exceldata = [];
let filtered_data = [];

// Read Excel
export function ReadExcelforCheque(file) {

  const readExcel= async (file)=>{
    const promise = new Promise((res, rej)=>{
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload= async (e)=>{
        const bufferArray = e.target.result;

        const wb = await XLSX.read(bufferArray, {type: 'buffer'});
        const ws = wb.Sheets[wb.SheetNames[0]];
        const data = XLSX.utils.sheet_to_json(ws, {raw: false});
        res(data);
      };

      fileReader.onerror = (error) => {
        rej(error);
      };
    });

    promise.then(async (d)=>{
      // Add to global array variable;
      exceldata = Array.from(d);
      filtered_data = await filter_excel_data(exceldata);
      for (var key of Object.keys(filtered_data)) {
        console.log(filtered_data[key])
      }
    });
  };

  readExcel(file);
};

// Filter excel data
async function filter_excel_data(data) {
  let valid_data = [];
  let out_data = [];
  // Filter out empty cheque cells
  for (var key_valid of Object.keys(data) ) {
    if(!data[key_valid].Cheque){
      continue;
    }
    else {
      valid_data.push(data[key_valid]);
    }
  }
  
  // Fill the first data
  if(valid_data) {
    // if invoice Amount is blank, fill with 0
    if (!valid_data[0]['Invoice Amount']) {
      valid_data[0]['Invoice Amount'] = '0';
    }
    out_data.push(valid_data[0]); 
  }

  let is_first_element = true;

  // Filter and combine same cheque numbers
  if(out_data) {
    for (var key of Object.keys(valid_data)) {
      let is_found = false;
      for (var key_out of Object.keys(out_data)) {
        if(is_first_element) {
          is_found = true;
          is_first_element = false;
          continue;
        }
        // The same cheque number should be combined
        if(out_data[key_out].Cheque === valid_data[key].Cheque) {
          if(valid_data[key]['Date']) {
            out_data[key_out]['Date'] = valid_data[key]['Date'];
          }
          out_data[key_out]['Invoice'] = out_data[key_out]['Invoice'].concat(', ', valid_data[key]['Invoice']);
          out_data[key_out]['Invoice Amount'] = String(parseFloat(out_data[key_out]['Invoice Amount'].replace(",","")) + parseFloat(valid_data[key]['Invoice Amount'].replace(",","")));
          if(valid_data[key]['Credit Note'] && (valid_data[key]['Credit Note'] !== 'Not Applicable')) {
            out_data[key_out]['Credit Note'] = valid_data[key]['Credit Note'];
          }
          if(valid_data[key]['CN Amount'] && (valid_data[key]['CN Amount'] !== '0')) {
            out_data[key_out]['CN Amount'] = valid_data[key]['CN Amount'];
          }
          if(valid_data[key]['TDS Amount'] && (valid_data[key]['TDS Amount'] !== '0')) {
            out_data[key_out]['TDS Amount'] = valid_data[key]['TDS Amount'];
          }
          if(valid_data[key]['Final Amount'] && (valid_data[key]['Final Amount'] !== '0')) {
            out_data[key_out]['Final Amount'] = valid_data[key]['Final Amount'];
          }
          if(valid_data[key]['Customer ID']) {
            out_data[key_out]['Customer ID'] = valid_data[key]['Customer ID'];
          }
          if(valid_data[key]['Recipient']) {
            out_data[key_out]['Recipient'] = valid_data[key]['Recipient'];
          }
          is_found = true;
          break;
        }
      }
      if(!is_found) {
        // if invoice Amount is blank, fill with 0
        if (!valid_data[key]['Invoice Amount']) {
          valid_data[key]['Invoice Amount'] = '0';
        }
        out_data.push(valid_data[key]);
      }
    }
  }

  return out_data;
}

// Send Email
export async function SendEmail() {
  const send_cheque_email = functions.httpsCallable('send_cheque_deposit_notification_emails');
  send_cheque_email(filtered_data).then(result => {
    console.log(result.data);
  })
  console.log("email sent");
  exceldata = [];
  filtered_data = [];
}