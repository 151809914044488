import pdfArrow from '../assets/images/arrow.png'
var pdfmake = require('pdfmake/build/pdfmake')
var pdffonts = require('pdfmake/build/vfs_fonts')
var XLSX = require('xlsx')

// PDF Generator
pdfmake.vfs = pdffonts.pdfMake.vfs;

let exceldata = [];

// Read Excel
export function ReadExcel(file) {

  const readExcel= async (file)=>{
    const promise = new Promise((res, rej)=>{
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload= async (e)=>{
        const bufferArray = e.target.result;

        const wb = await XLSX.read(bufferArray, {type: 'buffer'});
        const ws = wb.Sheets[wb.SheetNames[0]];
        const data = XLSX.utils.sheet_to_json(ws);
        res(data);
      };

      fileReader.onerror = (error) => {
        rej(error);
      };
    });

    promise.then((d)=>{
      // Add to global array variable;
      exceldata = Array.from(d);
    });
  };

  readExcel(file);
};

function getBase64ImageFromURL(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
  
    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
  
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
  
      var dataURL = canvas.toDataURL("image/png");
  
      resolve(dataURL);
    };
  
    img.onerror = error => {
      reject(error);
    };
  
    img.src = url;
  });}
  

// Download PDF
export async function PdfGen(clientID, company) {

  let filtered_data = [];
  // Create database
  for (var key of Object.keys(exceldata)) {
    if (exceldata[key].Cases !== 0 && !isNaN(exceldata[key].Cases) ) {
      filtered_data.push(exceldata[key]);
    }
  }

  // Handle multiple invoices
  for (var key of Object.keys(exceldata)) {
    if (exceldata[key].Cases === 0 || isNaN(exceldata[key].Cases) ) {
      for (var filter_key of Object.keys(filtered_data)) {
        if ((exceldata[key]["Customer Name"] === filtered_data[filter_key]["Customer Name"]) && (exceldata[key]["Place"] === filtered_data[filter_key]["Place"])) {
          filtered_data[filter_key]["Invoice Number"] = filtered_data[filter_key]["Invoice Number"].concat(",\n",exceldata[key]["Invoice Number"]);
        }
      }
    }
  }

  // Create PDF page content
  var dd = {
    content: [
    ],
    styles: {
      header: {
        fontSize: 26,
        bold: true,
        alignment: 'center',
        decoration: 'underline'
      },
      arrow: {
        alignment: 'center',
        margin: [0, 20, 0, 0]
      },
      scanhere: {
        fontSize: 20,
        bold: true,
        alignment: 'center',
        margin: [0, 20, 0, 0]
      },
      tabletext: {
        fontSize: 14,
        alignment: 'center',
        margin: [0, 100, 0, 0]
      }
    }
  }

  // Set all variables for pdf
  const data_len = filtered_data.length;
  var cnt = 1;
  var company_name = company;
  var company_id = clientID;

  // Add page content to pdf from the data array
  for (var key of Object.keys(filtered_data)) {
    // Extract information
    var invoices = filtered_data[key]["Invoice Number"];
    if ( invoices.includes(',') ) {
      var first_invoice = invoices.slice(0,invoices.indexOf(','));
    }
    else {
      var first_invoice = invoices;
    }
    var cases = filtered_data[key]["Cases"];
    var customer_name = filtered_data[key]["Customer Name"];
    var customer = customer_name.replace(/\s+/g, '%');
    var place = filtered_data[key]["Place"];
    var qrtext = "itraze&";
    qrtext = qrtext.concat("user=",company_id);
    qrtext = qrtext.concat("&inv_id=",invoices);
    qrtext = qrtext.concat("&cust=",customer);
    qrtext = qrtext.concat("&cases=",cases);

    // Push to page content
    dd.content.push({
      text: company_name,
      style: 'header'
    },
    {
        qr: qrtext,
        alignment: 'center',
        margin: [0, 50, 0, 0],
        fit: 200
    },
    {
      image: await getBase64ImageFromURL(pdfArrow),
      fit: [25, 25],
      style: 'arrow'
    },
    {
      text: "SCAN HERE TO CONFIRM DELIVERY",
      style: 'scanhere'
    },
    {
      columns: [
        { width: '*', text: '' },
        {
          width: 'auto',
          style: 'tabletext',
          table: {
            widths: [120,'auto'],
            heights: 25,
            body: [
              ['Invoice ID', {text: first_invoice, bold: true, fontSize: 16}],
              ['Number of Cases', {text: cases, bold: true, fontSize: 16}],
              ['Customer', {text: customer_name, bold: true, fontSize: 16}],
              ['Place', {text: place, bold: true, fontSize: 16}]
            ],
            alignment: "center"
          }
        },
        { width: '*', text: '' },
      ]
  }
    );

    // Handle page break except in last page
    if (cnt === data_len) {
        dd.content.push({
            text:"",
        });
        cnt = 1;
    }
    else {
        dd.content.push({
            text:"",
            pageBreak: "after"
        })
        cnt++;
    }
  }

  // Clear excel data array
  console.log(exceldata);
  console.log(filtered_data);
  exceldata = [];
  filtered_data = [];
  
  // Download the created PDF file
  var today = new Date();
  var day = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  var hours = today.getHours();
  var minutes = today.getMinutes();
  today = day + '-' + mm + '-' + yyyy + '_' + hours + '-' + minutes;
  var filename = "QR_"+today+".pdf";
  var pdf = pdfmake.createPdf(dd);
  pdf.download(filename);
};