import React, { useEffect, useState }from 'react'
import { Alert, Container, Col, Row } from 'react-bootstrap'
import { useAuth } from "../contexts/AuthContext"
import { useNavigate} from "react-router-dom"
import { db } from '../firebase'
import { Sidebar } from "../components/Sidebar"
import './ChequeNotificationPage.css'
import ChequeNotify from './ChequeNotify'
import { useSharedFormState } from '../contexts/SharedContext'

function Dashboard() {
  const [loading, setLoading] = useState(true)
  const [client, setClient] = useState()
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const navigate = useNavigate()
  const { userdata, setUserdata } = useSharedFormState()

  // Get data from firestore
  let getClientFromFirebase = {};
  useEffect( () => {
    const client = db.collection('user-credentials').where('username', "==", currentUser.email)
    .onSnapshot((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        getClientFromFirebase = {
          ...doc.data(),
          key: doc.id,
        };
      });
      setClient(getClientFromFirebase);
      setUserdata(getClientFromFirebase);
      setLoading(false);
    });

    return () => client();
  }, [currentUser.email]);


  if (loading) {
    return <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
  }
 
  async function handleLogout() {
    setError("")
    try {
      await logout()
      navigate("/login")
    } catch {
      setError("Failed to log out")
    }
  }

  return (
    <>
      <div className='dashboard'>
        <Container fluid style={{ paddingLeft:0, paddingRight:0, position:'fixed'}}>
          <Row>
            <Col className='p-0' style={{ maxWidth: "300px"}}>
              <Sidebar logout={handleLogout}/>
            </Col>
            <Col>
              <Row className='topbar'>
                <Col>
                  <div className='topbar-text'>
                    {client.name_in_web}
                  </div>
                </Col>
              </Row>
              <Row className='workarea'>
                <Col>
                  <ChequeNotify client={client} />
                  {error && <Alert variant="danger">{error}</Alert>}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Dashboard