import React from 'react';

const NotFound = () => {
  return (
    <div className='d-flex justify-content-center mt-4'>
      <h2>404 Page not found</h2>
    </div>
  )
}

export default NotFound