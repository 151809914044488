import React from "react";
import LoginPage from "./pages/LoginPage";
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings";
import NotFound from "./pages/NotFound";
import ForgotPassword from "./pages/ForgotPassword";
import ChequeNotification from "./pages/ChequeNotificationPage";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Claims from "./pages/Claims";

function App() {

  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route exact path="/" element={<PrivateRoute> <Dashboard /> </PrivateRoute>} />
          <Route path="/login" element={<LoginPage/>} />
          <Route path="/chequenotification" element={<PrivateRoute> <ChequeNotification /> </PrivateRoute>} />
          <Route path="/claims" element={<PrivateRoute> <Claims /> </PrivateRoute>} />
          <Route path="/settings" element={<PrivateRoute> <Settings /> </PrivateRoute>} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="*" element={<NotFound/>} />
        </Routes>
      </AuthProvider>
    </Router>
  )
}

export default App;
