import React, { useEffect, useState, useRef }from 'react'
import { Button, Alert, Container, Col, Row, Card, Form } from 'react-bootstrap'
import { useAuth } from "../contexts/AuthContext"
import { useNavigate} from "react-router-dom"
import { db } from '../firebase'
import { Sidebar } from "../components/Sidebar"
import './Settings.css'


function Settings() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const complaintsemailRef = useRef()
  const deliveryemailRef = useRef()
  const [loading, setLoading] = useState(true)
  const [client, setClient] = useState()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const { currentUser, logout, updatePassword, updateEmail } = useAuth()
  const navigate = useNavigate()
  const [isDelEdited, setIsDelEdited] = useState(false)
  const [isCompEdited, setIsCompEdited] = useState(false)

  // Get data from firestore
  let getClientFromFirebase = {};
  useEffect( () => {
    const client = db.collection('user-credentials').where('username', "==", currentUser.email)
    .onSnapshot((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        getClientFromFirebase = {
          ...doc.data(),
          key: doc.id,
        };
      });
      setClient(getClientFromFirebase);
      setLoading(false);
    });

    return () => client();
  }, [currentUser.email]);


  if (loading) {
    return <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
  }
 
  function onDelEditHandler() {
    setIsDelEdited(true)
  }

  function onCompEditHandler() {
    setIsCompEdited(true)
  }

  async function handleLogout() {
    setError("")
    try {
      await logout()
      navigate("/login")
    } catch {
      setError("Failed to log out")
    }
  }

  function handleDeliveryEmail(e) {
    e.preventDefault()
    setMessage("")
    setError("")
    db.collection('user-credentials').doc(client.key).update({
      delivery_email: deliveryemailRef.current.value
    }).then(() => {
      setMessage("Updated Email successfully")
      setTimeout(() => setMessage(""), 3000);
    })
    .catch(() => {
      setError("Failed to update Email")
      setTimeout(() => setError(""), 3000);
    })
  }

  function handleComplaintsEmail(e) {
    e.preventDefault()
    setMessage("")
    setError("")
    if (complaintsemailRef.current.value)
    db.collection('user-credentials').doc(client.key).update({
      complaints_email: complaintsemailRef.current.value
    }).then(() => {
      setMessage("Updated Email successfully")
      setTimeout(() => setMessage(""), 3000);
    })
    .catch(() => {
      setError("Failed to update Email")
      setTimeout(() => setError(""), 3000);
    })
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (!passwordRef.current.value) {
      return setError("Enter a new password")
    }

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    const promises = []
    setLoading(true)
    setError("")
    setMessage("")
    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value))
    }
    if (passwordRef.current.value) {
      promises.push(updatePassword(passwordRef.current.value))
    }

    Promise.all(promises)
      .then(() => {
        setMessage("Password reset successfully")
      })
      .catch(() => {
        setError("Failed to update account")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <div className='dashboard'>
        <Container fluid style={{ paddingLeft:0, paddingRight:0, position:'fixed'}}>
          <Row>
            <Col className='p-0' style={{ maxWidth: "300px"}}>
              <Sidebar logout={handleLogout}/>
            </Col>
            <Col>
              <Row className='topbar'>
                <Col>
                  <div className='topbar-text'>
                    {client.name_in_web}
                  </div>
                </Col>
              </Row>
              <Row className='workarea'>
                <Col>
                  <div className='settings--area'>
                    <h4 className='mb-4'>General</h4>
                    <div>
                    <Form onSubmit={handleDeliveryEmail} className='w-100'>
                      <Row>
                        <Col>
                          <Form.Label className='mt-2'>Delivery Confirmation Email</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control
                            type="email"
                            ref={deliveryemailRef}
                            required
                            defaultValue={client.delivery_email}
                            className="general-control"
                            onChange={onDelEditHandler}
                          />
                        </Col>
                        <Col>
                            <Button disabled={loading || !isDelEdited} className="btn--change" type="submit">
                              Change
                            </Button>
                        </Col>
                      </Row>
                    </Form>
                    <Form onSubmit={handleComplaintsEmail} className="mt-4 mb-4">
                      <Row>
                        <Col>
                          <Form.Label className='mt-1'>Complaints Email</Form.Label>
                        </Col>
                        <Col>
                          <Form.Control
                            type="email"
                            ref={complaintsemailRef}
                            required
                            defaultValue={client.complaints_email}
                            className="general-control"
                            onChange={onCompEditHandler}
                          />
                        </Col>
                        <Col>
                          <Button disabled={loading || !isCompEdited} className="btn--change" type="submit">
                            Change
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    </div>
                    <h4>Change Password</h4>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group id="email">
                        <Form.Label className='mt-2'>Username</Form.Label>
                        <Form.Control
                          type="email"
                          ref={emailRef}
                          required
                          defaultValue={currentUser.email}
                          disabled={true}
                        />
                      </Form.Group>
                      <Form.Group id="password">
                        <Form.Label className='mt-2'>New Password</Form.Label>
                        <Form.Control
                          type="password"
                          ref={passwordRef}
                          placeholder=""
                        />
                      </Form.Group>
                      <Form.Group id="password-confirm">
                        <Form.Label className='mt-2'>Confirm New Password</Form.Label>
                        <Form.Control
                          type="password"
                          ref={passwordConfirmRef}
                          placeholder=""
                        />
                      </Form.Group>
                      <Button disabled={loading} className="mt-4 mb-4 btn--resetpass" type="submit">
                        Reset Password
                      </Button>
                    </Form>
                    {error && <Alert variant="danger" className='text-center'>{error}</Alert>}
                    {message && <Alert variant="success" className='text-center'>{message}</Alert>}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Settings