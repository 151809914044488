import React from "react";
import { Container } from "react-bootstrap";
import Login from "../components/Login";
import "./LoginPage.css"
import logo from '../assets/images/logo.svg'

export default function LoginPage() {
	return (
		<div className="loginpage">
			<div className="logo-div">
				<img src={logo} alt="" className='logo-sym'/>
			</div>
			<Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh"}}>
					<div style={{ maxWidth: "400px"}}>
						<Login />
						<p className="copyright">Copyright © 2022 itraze. All rights reserved</p>
					</div>
			</Container>
		</div>
		
	)
}