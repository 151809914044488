import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert} from 'react-bootstrap'
import { useAuth } from "../contexts/AuthContext"
import "./Login.css"
import { Link, useNavigate } from "react-router-dom"

export default function Login() {
  const usernameRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
      await login(usernameRef.current.value, passwordRef.current.value)
      navigate("/")
    } catch {
      setError("Failed to log in")
    }

    setLoading(false)
  }

  return (
    <>
      <Card className='login'>
        <Card.Body>
          <h2 className="text-center mt-4 mb-4 h2--name">Member Login</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="d-flex justify-content-center" id="username">
              <Form.Control className='mt-4 form--control' type="username" ref={usernameRef} required placeholder='Username'>
              </Form.Control>
            </Form.Group>
            <Form.Group className="d-flex justify-content-center" id="password">
              <Form.Control className='mt-3 form--control' type="password" ref={passwordRef} required placeholder='Password'/>
            </Form.Group>
            <div className='text-center'>
              <Button disabled={loading} variant='light' className="mb-2 mt-5 btn--login" type="submit">
                <div className='login--text'>Login</div>
              </Button>
            </div>
            <div className="d-flex justify-content-center text-center mt-2 mb-4">
              <Link to="/forgotpassword" style={{ textDecoration: 'none'}}><div className='link'>Forgot Password?</div></Link>
            </div>
          </Form>
          {error && <Alert variant="danger" className='text-center'>{error}</Alert>}
        </Card.Body>
      </Card>
    </>
  )
}
