import React, { useState } from "react";
import { useBetween } from "use-between";

// Make a custom hook with your future shared state
const useFormState = () => {
  const [userdata, setUserdata] = useState();
  return {
    userdata, setUserdata
  };
};

// Make a custom hook for sharing your form state between any components
export const useSharedFormState = () => useBetween(useFormState);