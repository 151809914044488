import React, { useRef, useState } from 'react'
import { PdfGen, ReadExcel } from "../utils/PdfGenerator"
import { Button } from 'react-bootstrap'
import './QrcodePage.css'
import uploadicon from '../assets/images/upload-cloud.svg'
import fileicon from '../assets/images/file.svg'
import { Alert } from 'react-bootstrap'

function QrcodePage(props) {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [error, setError] = useState("")
  const [isFileExists, setIsFileExists] = useState(false)

  const wrapperRef = useRef(null);

  const onDragEnter = () => wrapperRef.current.classList.add('dragover');

  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  if (loading) {
    document.getElementById('fileText').innerText = "Processing ...";
  }
  
  async function btnPdfGen() {
    if (!isFileExists) {
      setError("No File chosen. Please upload a file")
      setTimeout(() => setError(""), 2500);
      return
    }

    if (!props.client.key) {
      setError("Error creating QR Codes. Reload page or check your internet connection and try again")
      return
    }
    setLoading(true);
    setMessage("")
    await PdfGen(props.client.key,props.client.name_in_app);
    setLoading(false);
    setIsFileExists(false)
    setMessage("QR Codes generated successfully");
    setTimeout(() => setMessage(""), 4000);
    document.getElementById('fileInput').value=null;
    document.getElementById('imgid').src = uploadicon
    document.getElementById('fileText').innerText = "Drag & Drop your files here or Click to Browse";
  }

  function btnClear() {
    document.getElementById('fileInput').value=null;
    document.getElementById('fileText').innerText = "Drag & Drop your files here or Click to Browse";
    document.getElementById('imgid').src = uploadicon
    setMessage("")
    setError("")
    setIsFileExists(false)
  }

  function fileChange(fileName) {
    document.getElementById('fileText').innerText=fileName;
    document.getElementById('imgid').src = fileicon
    setMessage("")
    setIsFileExists(true)
  }

  const onChangeFile = e => {
    const file = e.target.files[0]
    ReadExcel(file)
    fileChange(file.name)
  }

  return (
    <div className='div-container'>
      <p className='text-heading'>Delivery QR Code Generator</p>
      <div ref={wrapperRef} 
          className='drop-file-input'
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
          >
        <div className='drop-file-input__label'>
          <img id='imgid' src={uploadicon} alt="" className='icon'/>
          <p id="fileText">Drag & Drop your files here or Click to Browse</p>
          {message && <Alert variant="success">{message}</Alert>}
          {error && <Alert variant="danger">{error}</Alert>}
        </div>
        <input id="fileInput" name="fileInput" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={onChangeFile}
        />
      </div>
      <div className='btn-container'>
        <Button size='lg' className='btn-clear' style={{color: "#302eb5"}} onClick={btnClear}>Clear</Button>
        <Button size='lg' className='btn-gen' onClick={btnPdfGen}>Generate</Button>
      </div>
    </div>
  )
}

export default QrcodePage