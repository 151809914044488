import React from 'react';
import './ComingSoon.css'

const ComingSoon = () => {
  return (
    <div className="wrapper">
      <h1>coming soon<span className="dot">.</span></h1>
    </div>
  )
}

export default ComingSoon