import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext"
import { Link } from "react-router-dom"
import "./ForgotPassword.css"
import logo from '../assets/images/logo.svg'

export default function LoginPage() {
  const emailRef = useRef()
  const userRef = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setMessage("")
      setError("")
      setLoading(true)
      await resetPassword(emailRef.current.value)
      setMessage("Reset link has been to your Email")
    } catch {
      setError("Failed to reset password")
    }

    setLoading(false)
  }

	return (
		<div className="loginpage">
			<div className="logo-div">
				<img src={logo} alt="" className='logo-sym'/>
			</div>
			<Container className="d-flex align-items-center justify-content-center" style={{ minHeight: "100vh"}}>
					<div style={{ maxWidth: "400px"}}>
            <Card className="reset--card">
              <Card.Body>
                <h2 className="text-center mb-4">Password Reset</h2>
                <Form onSubmit={handleSubmit}>
                  <Form.Group id="email">
                    <Form.Control type="email" className='w-100 mt-3 form--control' ref={emailRef} required placeholder="Enter Email ID"/>
                  </Form.Group>
                  <Button disabled={loading} className="w-100 mt-4 btn--reset" type="submit">
                    Reset Password
                  </Button>
                </Form>
                <div className="w-100 text-center mt-3 mb-2">
                  <Link to="/login">Back to Login</Link>
                </div>
                {error && <Alert variant="danger" className='text-center'>{error}</Alert>}
                {message && <Alert variant="success" className='text-center'>{message}</Alert>}
              </Card.Body>
            </Card>
					</div>
			</Container>
		</div>
	)
}